export default {
	i18n: {
		messages: {
			ru: {
				'html.buAuto.autocheck21': 'Диагностика ходовой части',
				'html.buAuto.autocheck23': 'Осмотр двигателя',
				'html.buAuto.autocheck25': 'Проверка кузова',
				'html.buAuto.autocheck30': 'Проверка световых приборов',
				'html.buAuto.autocheck32': 'Тестовая поездка',
				'html.buAuto.autocheck34': 'Проверка развал-схождения',
				'html.buAuto.autocheck36': 'Фотоотчет',
				'html.buAuto.autocheck38': 'Компьютерная диагностика',
				'html.buAuto.autocheck73': 'Осмотр салона',
				'html.buAuto.autocheck75': 'Проверка достоверности документов на авто',
				'html.buAuto.autocheck149': 'Узнайте все о техническом состоянии авто',
				'html.buAuto.autocheck150': '9 проверок от лучших СТО страны',
				'html.buAuto.autocheck151': 'Узнайте все о юридической чистоте авто',
				'html.buAuto.autocheck152': '2 проверки от МВД Украины',
				'html.buAuto.autocheck153': 'Узнайте еще больше об авто, проверенном по VIN-коду',
				'html.buAuto.autocheck154': 'Онлайн проверка',
				'данные об угоне и арестах': 'Данные об угоне и арестах',
				'ДТП и страховые случаи': 'ДТП и страховые случаи',
				'кредиты, залоги, аренда': 'Кредиты, залоги, аренда',
				'проверка истории пробега': 'Истории пробега и проверка на скручивание',
				'наличие страхового полиса': 'Наличие страхового полиса',
				'государственная регистрация': 'Государственная регистрация',
				'информация о модели': 'Информация о модели',
				'данные о комплектации': 'Данные о комплектации',
				'история сервисного обслуживания': 'История сервисного обслуживания',
				'ремонты и замена деталей': 'Ремонты и замена деталей',
				'Проверка подлинности автомобиля': 'Проверка подлинности автомобиля',
				'Подробнее': 'Подробнее',
				'Основные характеристики и данные о комплектации': 'Основные характеристики и данные о комплектации',
				'История всех зафиксированных пробегов и проверка на скручивание': 'История всех зафиксированных пробегов и проверка на скручивание',
				'ДТП в Украине, Европе, США и Южной Корее': 'ДТП в Украине, Европе, США и Южной Корее',
				'Наличие страхового полиса': 'Наличие страхового полиса',
				'История регистраций в Украине, США и Европе': 'История регистраций в Украине, США и Европе',
				'Проверка количества собственников в Украине, США и Европе': 'Проверка количества собственников в Украине, США и Европе',
				'Угоны, аресты и другие обременения': 'Угоны, аресты и другие обременения',
				'Ремонты у официального дилера': 'Ремонты у официального дилера',
				'Сервисное обслуживание': 'Сервисное обслуживание',

			},
			uk: {
				'html.buAuto.autocheck21': 'Діагностика ходової частини',
				'html.buAuto.autocheck23': 'Огляд двигуна',
				'html.buAuto.autocheck25': 'Перевірка кузова',
				'html.buAuto.autocheck30': 'Перевірка світлових пристроїв',
				'html.buAuto.autocheck32': 'Тестова поїздка',
				'html.buAuto.autocheck34': 'Перевірка розвалу-сходження',
				'html.buAuto.autocheck36': 'Фотозвіт',
				'html.buAuto.autocheck38': 'Комп’ютерна діагностика',
				'html.buAuto.autocheck73': 'Огляд салона',
				'html.buAuto.autocheck75': 'Перевірка достовірності документів на авто',
				'html.buAuto.autocheck149': 'Дізнайтеся про технічний стан авто',
				'html.buAuto.autocheck150': '9 перевірок від кращих СТО країни',
				'html.buAuto.autocheck151': 'Дізнайтеся все про юридичну чистоту авто',
				'html.buAuto.autocheck152': '2 перевірки від МВС України',
				'html.buAuto.autocheck153': 'Дізнайтеся ще більше про авто, перевірене по VIN-коду',
				'html.buAuto.autocheck154': 'Онлайн перевірка',
				'данные об угоне и арестах': 'Данні про викрадення та арешти',
				'ДТП и страховые случаи': 'ДТП та страхові випадки',
				'кредиты, залоги, аренда': 'Кредити, застави, оренда',
				'проверка истории пробега': 'Історія пробігу і перевірка на скручування',
				'наличие страхового полиса': 'Наявність страхового полісу',
				'государственная регистрация': 'Державна реєстрація',
				'информация о модели': 'Інформація про модель',
				'данные о комплектации': 'Данні про комплектацію',
				'история сервисного обслуживания': 'Історія сервісного обслуговування',
				'ремонты и замена деталей': 'Ремонти та заміна деталей',
				'Проверка подлинности автомобиля': 'Перевірка легальності походження автомобіля',
				'Подробнее': 'Детальніше',
				'Основные характеристики и данные о комплектации': 'Основні характеристики і дані про комплектацію',
				'История всех зафиксированных пробегов и проверка на скручивание': 'Історія всіх зафіксованих пробігів і перевірка на скручування',
				'ДТП в Украине, Европе, США и Южной Корее': 'ДТП в Україні, Європі, США та Південній Кореї',
				'Наличие страхового полиса': 'Наявність страхового поліса',
				'История регистраций в Украине, США и Европе': 'Історія реєстрацій в Україні, США та Європі',
				'Проверка количества собственников в Украине, США и Европе': 'Перевірка кількості власників в Україні, США та Європі',
				'Угоны, аресты и другие обременения': 'Угони, арешти та інші обтяження',
				'Ремонты у официального дилера': 'Ремонти у офіційного дилера',
				'Сервисное обслуживание': 'Сервісне обслуговування',
			},
		}
	}
};

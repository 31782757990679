export default {
	i18n: {
		messages: {
			ru: {
				'из': 'из',
				'Оставить заявку': 'Оставить заявку на проверку авто',
				'проверенных авто': 'проверенных авто',
				'продаются в течение': 'продаются в течение',
				'часов': 'часов',
			},
			uk: {
				'из': 'з',
				'Оставить заявку': 'Залишити заявку на перевірку авто',
				'проверенных авто': 'перевірених авто',
				'продаются в течение': 'продаються протягом',
				'часов': 'годин',
			},
		}
	}
};

const lelekaClient = require('lelekaClient');

export default {
	data() {
		return ({
			toggle: 0
		});
	},
	i18n: {
		messages: {
			ru: {
				'Покупайте уверенно': 'Покупайте уверенно',
				'html.buAuto.autocheck136': 'Продавайте быстро',
				'html.buAuto.autocheck139': 'Объявление получает статус',
				'Как заказать проверку авто?': 'Как заказать проверку авто?',
				'html.buAuto.autocheck141': 'Мы вернём деньги, если проверка авто не произойдёт',
				'html.buAuto.autocheck142': 'Аргументируйте реальную цену авто',
				'html.buAuto.autocheck143': 'Увеличьте доверие к предложению, добавив к нему результаты проверки',
				'html.buAuto.autocheck144': 'Получите достоверную информацию о техническом состоянии авто',
				'html.buAuto.autocheck145': 'Проверьте юридическую «чистоту» автомобиля',
				'html.buAuto.autocheck146': 'Узнайте, соответствует ли состояние авто указанной цене',
				'html.buAuto.autocheck147': 'Проверьте авто из другого города, не выезжая из дома',
				'html.buAuto.autocheck148': 'при заказе',
				'html.buAuto.proverki': 'проверки',
				'html.buAuto.dnei': 'дней',
				'html.buAuto.autocheck.technical': 'технической',
				'html.buAuto.autocheck.juristical': 'юридической',
				'html.buAuto.ili': 'или',
			},
			uk: {
				'Покупайте уверенно': 'Купуйте впевнено',
				'html.buAuto.autocheck136': 'Продавайте швидко',
				'html.buAuto.autocheck139': 'Оголошення отримує статус',
				'Как заказать проверку авто?': 'Як замовити перевірку авто?',
				'html.buAuto.autocheck141': 'Ми повернемо гроші, якщо перевірка авто не відбудеться',
				'html.buAuto.autocheck142': 'Аргументуйте реальну ціну авто',
				'html.buAuto.autocheck143': 'Збільшіть довіру до пропозиції, додавши до неї результати перевірки',
				'html.buAuto.autocheck144': 'Отримайте достовірну інформацію про технічний стан авто',
				'html.buAuto.autocheck145': 'Перевірте юридичну «чистоту» автомобіля',
				'html.buAuto.autocheck146': 'Дізнайтесь, чи відповідає стан авто вказаній ціні',
				'html.buAuto.autocheck147': 'Перевірте авто з іншого міста, не виїжджаючи з дому',
				'html.buAuto.autocheck148': 'при замовленні',
				'html.buAuto.proverki': 'перевірки',
				'html.buAuto.dnei': 'днів',
				'html.buAuto.autocheck.technical': 'технічної',
				'html.buAuto.autocheck.juristical': 'юридичної',
				'html.buAuto.ili': 'або',
			},
		}
	},
	mounted() {
		lelekaClient();
		window.riaHistoryStorage.addHistoryObject(23, 1);
	}
};

export default {
	name: 'Autotest',
	components: {
		'vue-banner': require('./Banner/index.vue').default,
		'vue-autotestmenu': require('./AutotestMenu/index.vue').default,
		'vue-advantages': require('./Advantages/index.vue').default,
		'vue-what-we-check': require('./WhatWeCheck/index.vue').default,
		'vue-how-to-check': require('./HowToCheck/index.vue').default,
		'vue-select-auto': require('./SelectAuto/index.vue').default,
		'vue-call-order': require('../CheckCar/CallOrder/index.vue').default,
		'vue-manager': require('../CheckCar/Manager/index.vue').default,
		'vue-about': require('./About/index.vue').default,
	},
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{name: 'description', content: this.description},
				{property: 'og:title', content: this.title},
				{property: 'og:description', content: this.description},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			],
		};
	},
	data() {
		return ({
			description: 'Перевірені авто на AUTO.RIA для безпечної покупки і швидкого продажу авто"',
			title: 'Перевірені авто на AUTO.RIA ',
		});
	}
};

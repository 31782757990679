export default {
	i18n: {
		messages: {
			ru: {
				'Технически': 'Технически',
				'Юридически': 'Юридически',
				'html.buAuto.autocheck137': 'Проверьте авто до того, как сделаете выбор',
			},
			uk: {
				'Технически': 'Технічно',
				'Юридически': 'Юридично',
				'html.buAuto.autocheck137': 'Перевірте авто до того, як зробите вибір',
			},
		}
	}
};

export default {
	messages: {
		ru: {
			'html.buAuto.autocheck77': 'Как проверить авто',
			'html.buAuto.autocheck78': 'Оставьте заявку',
			'html.buAuto.autocheck79': 'на проверку авто',
			'html.buAuto.autocheck82': 'Получите результаты проверки',
			'html.buAuto.autocheck141': 'Мы вернём деньги, если проверка авто не произойдёт',
			'html.buAuto.autocheck155': 'Ожидайте звонка менеджера',
			'html.buAuto.autocheck156': 'для уточнения деталей',
			'html.buAuto.autocheck157': 'Специалисты проверяют автомобиль',
			'html.buAuto.autocheck158': 'и составляют отчёт',
			'html.buAuto.autocheck159': 'на свой e-mail',
		},
		uk: {
			'html.buAuto.autocheck77': 'Як перевірити авто',
			'html.buAuto.autocheck78': 'Залиште заявку',
			'html.buAuto.autocheck79': 'на перевірку авто',
			'html.buAuto.autocheck82': 'Отримайте результати перевірки',
			'html.buAuto.autocheck141': 'Ми повернемо гроші, якщо перевірка авто не відбудеться',
			'html.buAuto.autocheck155': 'Очікуйте дзвінка менеджера',
			'html.buAuto.autocheck156': 'для уточнення деталей',
			'html.buAuto.autocheck157': 'Фахівці перевірять автомобіль',
			'html.buAuto.autocheck158': 'і складуть звіт',
			'html.buAuto.autocheck159': 'на свій e-mail',
		},
	}
};

import Vue from 'vue';
import {getReviewsAutotest} from '../../../store/queries';
import loopify from '../../../../app/helpers/loopify';

const REVIEW_TYPE = 1;
const REVIEWS_COUNT = 1;

export default {
	data() {
		return ({
			pagePrev_: 0,
			page: 0,
			total: '',
			reviews: {},
		});
	},
	computed: {
		reviewPrev() {
			const {[this.pagePrev_]: {comments: [review] = []} = {}} = this.reviews;
			return review;
		},
		reviewNow() {
			const {[this.page]: {comments: [review] = []} = {}} = this.reviews;
			return review;
		},
		review() {
			return this.reviewNow || this.reviewPrev || {};
		},
		text() {
			return this.review.text;
		},
		author() {
			return this.review.name || this.$t('Без имени');
		},
		date() {
			return this.review.add_date;
		},
		city() {
			return this.review.cityName;
		},
		cityId() {
			return this.review.city_id;
		},
		stateId() {
			return this.review.state_id;
		},
		station() {
			return this.review.stationName;
		},
		stationId() {
			return this.review.station_id;
		},
		recommedation() {
			return Number(this.review.recommed) === 1;
		},
	},
	methods: {
		getReviews(type, from) {
			return this.reviews[this.page] ?
				Promise.resolve(this.reviews[this.page]) :
				getReviewsAutotest(type, REVIEWS_COUNT, from, this.langId)
					.then((response) => {
						Vue.set(this.reviews, this.page, response);
						Vue.set(this, 'total', response.total);
					});
		},
		init() {
			return this.getReviews(REVIEW_TYPE, this.page).catch(console.error);
		},
		pagePrev() {
			this.pagePrev_ = this.page;
			this.page = loopify(this.page - 1, this.total || 1);
		},
		pageNext() {
			this.pagePrev_ = this.page;
			this.page = loopify(this.page + 1, this.total || 1);
		}
	},
	watch: {
		page() {
			return this.getReviews(REVIEW_TYPE, this.page);
		},
	},
	serverPrefetch() {
		return this.init();
	},
	mounted() {
		return this.init();
	},
	i18n: {
		messages: {
			ru: {
				'Что говорят о проверках на': 'Что говорят о проверках на',
				'Без имени': 'Без имени',
				'Смотреть все отзывы': 'Смотреть все отзывы',
			},
			uk: {
				'Что говорят о проверках на': 'Що кажуть про перевірки на',
				'Без имени': 'Без імені',
				'Смотреть все отзывы': 'Дивитися всі відгуки',
			},
		}
	}
};
